//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SubscriptionPlanCard from '@/components/Cards/SubscriptionPlanCard.vue';

export default {
    components: { SubscriptionPlanCard },
    data() {
        return {
            billingMode: 'monthly',
            features: {
                all_functionalities: {
                    title: this.$t('pricing.features.all_functionalities.title'),
                    description: this.$t('pricing.features.all_functionalities.description'),
                },
                all_starter_functionalities: {
                    title: this.$t('pricing.features.all_functionalities.title_starter_plan'),
                    description: this.$t('pricing.features.all_functionalities.description_starter_plan'),
                },
                all_premium_functionalities: {
                    title: this.$t('pricing.features.all_functionalities.title_premium_plan'),
                    description: this.$t('pricing.features.all_functionalities.description_premium_plan'),
                },
                free_trial: {
                    title: this.$t('pricing.features.free_trial.title'),
                    description: this.$t('pricing.features.free_trial.description'),
                },
                api_access: {
                    title: this.$t('pricing.features.api_access.title'),
                    description: this.$t('pricing.features.api_access.description'),
                },
                app_integrations: {
                    title: this.$t('pricing.features.app_integrations.title'),
                    description: this.$t('pricing.features.app_integrations.description'),
                },
            },
        };
    },
};
