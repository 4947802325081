//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'BrandsCarousel',
    components: {},
    props: {
        fullscreen: {
            type: Boolean,
            required: false,
            default: false,
        },
        items: {
            type: Array,
            required: true,
        },
    },
    mounted() {
        this.carousel = new this.$swiper(this.$refs.swiper, {
            slidesPerView: 3,
            spaceBetween: 30,
            speed: 500,
            loop: true,
            simulateTouch: false,
            autoplay: {
                delay: 3000,
            },
            breakpoints: {
                576: {
                    slidesPerView: 4,
                },
                768: {
                    slidesPerView: 5,
                },
                1280: {
                    slidesPerView: 6,
                },
            },
        });
    },
    beforeDestroy() {
        this.carousel.destroy();
    },
};
