//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Hero from '@/components/Pages/Home/Hero';
import ProductFeatureBlock from '@/components/Pages/Home/ProductFeatureBlock';
import FeatureGridItem from '@/components/Pages/Home/FeatureGridItem.vue';
import SubscriptionPlan from '@/components/Pages/Home/SubscriptionPlan';
import ManageYourTeamsHolidayBlock from '@/components/Pages/Home/ManageYourTeamsHolidayBlock';
import MelkwegBanner from '@/components/Pages/Home/MelkwegBanner';
import ContactForm from '@/components/Forms/ContactForm';
import HowDoesItWorkVideo from '@/components/Pages/Home/HowDoesItWorkVideo';
import Pricing from '@/components/Pages/Home/Pricing';
import TestimonialCarousel from '@/components/Carousels/TestimonialCarousel.vue';
import BrandsCarousel from '@/components/Carousels/BrandsCarousel.vue';

export default {
    name: 'Index',
    components: {
        TestimonialCarousel,
        BrandsCarousel,
        Hero,
        FeatureGridItem,
        ProductFeatureBlock,
        MelkwegBanner,
        SubscriptionPlan,
        ManageYourTeamsHolidayBlock,
        ContactForm,
        HowDoesItWorkVideo,
        Pricing,
    },
    async asyncData({ $axios, error }) {
        let testimonials = [];
        let brands = [];
        const userCount = '1000';

        await $axios
            .$get('/landing')
            .then((res) => {
                if (res.success === 1) {
                    testimonials = res.data.testimonials;
                    brands = res.data.brands;
                    // userCount = res.data.user_count;
                }
            })
            .catch((err) => {
                return error({ statusCode: err.response?.status, message: err.response?.statusText });
            });

        return {
            testimonials,
            brands,
            userCount,
        };
    },
    data() {
        return {
            shortProductFeatureDescriptions: [
                {
                    icon: '🎈',
                    title: 'homepage.features.feature_2.title',
                    description: 'homepage.features.feature_2.description',
                },
                {
                    icon: '🤖',
                    title: 'homepage.features.feature_3.title',
                    description: 'homepage.features.feature_3.description',
                },
                {
                    icon: '👩🏻👨🏽👩‍',
                    title: 'homepage.features.feature_4.title',
                    description: 'homepage.features.feature_4.description',
                },
                {
                    icon: '🗂️',
                    title: 'homepage.features.feature_5.title',
                    description: 'homepage.features.feature_5.description',
                },
                {
                    icon: '🦜',
                    title: 'homepage.features.feature_6.title',
                    description: 'homepage.features.feature_6.description',
                },
                {
                    icon: '✨',
                    title: 'homepage.features.feature_7.title',
                    description: 'homepage.features.feature_7.description',
                },
            ],
            productFeatures: [
                {
                    imgSrcset: {
                        desktop: require(`~/assets/images/illustrations/desktop/${this.$i18n.locale}/feature-illustration-1.svg`),
                        mobile: require(`~/assets/images/illustrations/desktop/${this.$i18n.locale}/feature-illustration-1.svg`),
                    },
                    imgAlt: 'Illustration alt tag text',
                    title: 'homepage.details.detail_1.title',
                    description: 'homepage.details.detail_1.description',
                },
                {
                    isAltered: true,
                    imgSrcset: {
                        desktop: require(`~/assets/images/illustrations/desktop/${this.$i18n.locale}/feature-illustration-2.svg`),
                        mobile: require(`~/assets/images/illustrations/desktop/${this.$i18n.locale}/feature-illustration-2.svg`),
                    },
                    imgAlt: this.$t('Illustration alt tag text'),
                    title: 'homepage.details.detail_2.title',
                    description: 'homepage.details.detail_2.description',
                },
                {
                    imgSrcset: {
                        desktop: require(`~/assets/images/illustrations/desktop/${this.$i18n.locale}/feature-illustration-3.svg`),
                        mobile: require(`~/assets/images/illustrations/desktop/${this.$i18n.locale}/feature-illustration-3.svg`),
                    },
                    imgAlt: this.$t('Illustration alt tag text'),
                    title: 'homepage.details.detail_3.title',
                    description: 'homepage.details.detail_3.description',
                },
                {
                    isAltered: true,
                    imgSrcset: {
                        desktop: require(`~/assets/images/illustrations/desktop/${this.$i18n.locale}/feature-illustration-4.svg`),
                        mobile: require(`~/assets/images/illustrations/desktop/${this.$i18n.locale}/feature-illustration-4.svg`),
                    },
                    imgAlt: this.$t('Illustration alt tag text'),
                    title: 'homepage.details.detail_4.title',
                    description: 'homepage.details.detail_4.description',
                },
            ],
            responsiveOptions: [
                {
                    breakpoint: '1024px',
                    numVisible: 3,
                    numScroll: 3,
                },
                {
                    breakpoint: '600px',
                    numVisible: 2,
                    numScroll: 2,
                },
                {
                    breakpoint: '480px',
                    numVisible: 2,
                    numScroll: 2,
                },
            ],
        };
    },
};
