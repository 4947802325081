var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid grid-cols-12 gap-5",attrs:{"id":"contact-form-wrapper"}},[_c('div',{staticClass:"col-span-12 xs:col-span-10 xs:col-start-2 lg:col-span-8 lg:col-start-3"},[_c('p',{staticClass:"secondary-title text-center"},[_c('T',{attrs:{"t":"contact.lead"}})],1),_vm._v(" "),_c('h3',{staticClass:"title title--section text-center mt-4 lg:mt-6"},[_c('T',{attrs:{"t":"contact.title"}})],1),_vm._v(" "),_c('div',{staticClass:"relative mt-8 lg:mt-16"},[_vm._v("\n            "+_vm._s(/* The form */)+"\n            "),_c('div',{class:[
                    !_vm.isThankYouMessageVisible ? 'opacity-1 pointer-events-all' : 'opacity-0 pointer-events-none',
                    'transition-opacity' ]},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"w-full grid grid-cols-4 gap-x-5 gap-y-8",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{staticClass:"p-field col-span-4 lg:col-span-2",attrs:{"vid":"name","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{staticClass:"input-label"},[_c('T',{attrs:{"t":"contact.name"}})],1),_vm._v(" "),_c('InputText',{attrs:{"id":"name","name":"name","type":"text","placeholder":_vm.$t('contact.name'),"aria-describedby":"name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"p-field lg:w-auto col-span-4 lg:col-span-2",attrs:{"vid":"email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{staticClass:"input-label"},[_c('T',{attrs:{"t":"contact.email"}})],1),_vm._v(" "),_c('InputText',{attrs:{"id":"mail","type":"email","aria-describedby":"mail","placeholder":_vm.$t('contact.email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"p-field z-1 col-span-4 lg:col-span-2",attrs:{"vid":"subject","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{staticClass:"input-label"},[_c('T',{attrs:{"t":"contact.subject"}})],1),_vm._v(" "),_c('Dropdown',{attrs:{"placeholder":_vm.$t('generic.please_choose'),"options":_vm.subjects,"option-label":"label"},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"p-field col-span-4 lg:col-span-2 lg:col-start-3 lg:row-span-3 lg:row-start-1",attrs:{"vid":"body","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('Textarea',{staticClass:"min-h-40 w-full",attrs:{"auto-resize":false,"placeholder":_vm.$t('contact.message')},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_vm._v(" "),_c('InputError',{attrs:{"errors":errors}})]}}],null,true)}),_vm._v(" "),_c('Button',{staticClass:"p-button-primary w-full p-4 col-span-4 lg:col-span-2 lg:col-start-2 lg:mt-4",attrs:{"type":"submit"}},[_c('span',{staticClass:"p-text-bold"},[_c('T',{attrs:{"t":"contact.send_message"}})],1),_vm._v(" "),_c('i',{staticClass:"bx bx-paper-plane ml-2"})]),_vm._v(" "),(false)?_c('span',{staticClass:"mt-5 text-xs"},[_vm._v("\n                            "+_vm._s(_vm.$t('This site is protected by reCAPTCHA and the Google'))+"\n                            "),_c('a',{staticClass:"font-bold",attrs:{"href":"https://policies.google.com/privacy"}},[_c('T',{attrs:{"t":"contact.privacy_policy"}})],1),_vm._v("\n\n                            "+_vm._s(_vm.$t('contact.and'))+"\n                            "),_c('a',{staticClass:"font-bold",attrs:{"href":"https://policies.google.com/terms"}},[_c('T',{attrs:{"t":"contact.privacy_policy"}})],1),_vm._v("\n                            "+_vm._s(_vm.$t('contact.apply'))+".\n                        ")]):_vm._e()],1)]}}])})],1),_vm._v("\n\n            "+_vm._s(/* Thank you message */)+"\n            "),_c('div',{staticClass:"transition-opacity absolute top-0 left-0 w-full text-center",class:[
                    _vm.isThankYouMessageVisible ? 'opacity-1 pointer-events-all' : 'opacity-0 pointer-events-none' ]},[_c('p',{staticClass:"secondary-title mb-5"},[_c('T',{attrs:{"t":"contact.success_title"}})],1),_vm._v(" "),_c('p',{staticClass:"text-base mb-12"},[_c('T',{attrs:{"t":"contact.success_message"}})],1),_vm._v(" "),_c('Button',{staticClass:"w-full xs:w-auto",attrs:{"label":_vm.$t('contact.send_another_message')},on:{"click":_vm.closeThankYouScreen}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }