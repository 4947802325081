import { render, staticRenderFns } from "./FeatureGridItem.vue?vue&type=template&id=1daaf4e7&scoped=true&"
import script from "./FeatureGridItem.vue?vue&type=script&lang=js&"
export * from "./FeatureGridItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1daaf4e7",
  null
  
)

export default component.exports