import { render, staticRenderFns } from "./ProductFeatureBlock.vue?vue&type=template&id=7e311739&scoped=true&"
import script from "./ProductFeatureBlock.vue?vue&type=script&lang=js&"
export * from "./ProductFeatureBlock.vue?vue&type=script&lang=js&"
import style0 from "./ProductFeatureBlock.vue?vue&type=style&index=0&id=7e311739&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e311739",
  null
  
)

export default component.exports