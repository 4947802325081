//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            name: '',
            email: '',
            subject: null,
            message: null,
            subjects: [
                { label: this.$t('contact.option_1') },
                { label: this.$t('contact.option_2') },
                { label: this.$t('contact.option_3') },
            ],
            isThankYouMessageVisible: false,
        };
    },
    async mounted() {
        await this.$recaptcha.init();

        if (this.loggedIn) {
            this.name = `${this.$user.full_name}`;
            this.email = this.$user.email;
        }
    },
    beforeDestroy() {
        this.$recaptcha.destroy();
    },
    methods: {
        closeThankYouScreen() {
            this.isThankYouMessageVisible = false;
            this.name = '';
            this.email = '';
            this.subject = null;
            this.message = null;
        },
        async onSubmit() {
            try {
                // the execute method's param will be used as a label in the recaptcha admin console
                // const token = await this.$recaptcha.execute('contact');
                const token = await this.$recaptcha.execute('contact');

                await this.$axios
                    .$post(
                        '/recaptcha/validate/contact',
                        {
                            name: this.name,
                            email: this.email,
                            subject: this.subject ? this.subject.label : '',
                            body: this.message,
                        },
                        {
                            baseURL: '',
                            headers: {
                                'base-url': this.$axios.defaults.baseURL,
                                'recaptcha-token': token,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.success === 1) {
                            this.isThankYouMessageVisible = true;
                        } else {
                            this.$refs.form.setErrors(res.error);
                        }
                    });

                await this.$recaptcha.reset();
            } catch (error) {
                console.log(error);
            }
        },
    },
};
