//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TestimonialCard from '@/components/Cards/TestimonialCard.vue';

export default {
    name: 'TestimonialCarousel',
    components: {
        TestimonialCard,
    },
    props: {
        fullscreen: {
            type: Boolean,
            required: false,
            default: false,
        },
        items: {
            type: Array,
            required: true,
        },
    },
    mounted() {
        this.carousel = new this.$swiper(this.$refs.swiper, {
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 500,
            loop: true,
            simulateTouch: false,
            navigation: {
                nextEl: this.$refs.nextBtn,
                prevEl: this.$refs.prevBtn,
            },
            pagination: {
                el: this.$refs.pagination,
                clickable: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                1440: {
                    slidesPerView: 3,
                },
            },
        });
    },
    beforeDestroy() {
        this.carousel.destroy();
    },
};
