//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'HowDoesItWorkVideo',
    data() {
        return {
            overlayIsVisible: true,
        };
    },
    methods: {
        hideOverlay() {
            this.overlayIsVisible = !this.overlayIsVisible;
        },
    },
};
