//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'SubscriptionPlanCard',
    props: {
        title: {
            type: String,
            required: true,
        },
        lead: {
            type: String,
            required: true,
        },
        billingMode: {
            type: String,
            required: true,
        },
        pricing: {
            type: Object,
            required: false,
            default: () => {},
        },
        btnLabel: {
            type: String,
            required: true,
        },
        features: {
            type: Array,
            required: true,
        },
        transitionDelay: {
            type: Number,
            required: false,
            default: 0,
        },
        isPremium: {
            type: Boolean,
            required: false,
            default: false,
        },
        isFree: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
