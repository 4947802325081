//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        rating: {
            type: Number,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        firstName: {
            type: [String, Number],
            required: true,
        },
        lastName: {
            type: [String, Number],
            required: true,
        },
        position: {
            type: String,
            required: true,
        },
        company: {
            type: String,
            required: true,
        },
    },
};
